import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import PageBanner from '../components/PageBanner';
import SliceZone from '../slices/SliceZone';

const CompanyPage = ({data: {prismicCompanyPage}, location}) => {
  const {data} = prismicCompanyPage;
  return (
    <Layout customSEO>
      <SEO title={`${data.page_title.text}`} />
      <PageBanner bannerImage={data.page_banner} pageTitle={data.page_title.text} />
      {data.body && <SliceZone allSlices={data.body} />}
    </Layout>
  );
};

export default CompanyPage;

CompanyPage.propTypes = {
  location: PropTypes.object.isRequired
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicCompanyPage(uid: {eq: $uid}) {
      uid
      data {
        page_title {
          text
        }
        page_banner {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on PrismicCompanyPageBodyIntroText {
            slice_type
            id
            primary {
              title {
                text
              }
              quote {
                text
              }
              text {
                html
              }
              page_nav_title {
                text
              }
              include_in_page_nav
            }
          }
          ... on PrismicCompanyPageBodyTwoColumnText {
            slice_type
            id
            primary {
              include_in_page_nav
              page_nav_title {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicCompanyPageBodyContactBlock {
            slice_type
            id
            primary {
              sub_title {
                text
              }
              title {
                text
              }
              contact_details {
                html
              }
              location {
                latitude
                longitude
              }
            }
          }
          ... on PrismicCompanyPageBodyStaffBios {
            slice_type
            id
          }
          ... on PrismicCompanyPageBodyImageGallery {
            slice_type
            id
            primary {
              include_in_page_nav
              page_nav_title {
                text
              }
            }
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              image_caption {
                text
              }
            }
          }
          ... on PrismicCompanyPageBodyContactForm {
            slice_type
            id
            primary {
              include_in_page_nav
              page_nav_title {
                text
              }
            }
          }
        }
      }
    }
  }
`;
